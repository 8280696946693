html{
  scroll-behavior: smooth;
}
html,
body{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* background-color: #969696; */
  background-color: #f5f5f5;
  height: 100%;
}
#root{
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.container{
  flex: 1 0 auto;
  margin: 60px auto 0 auto;
  max-width: 100%;
}
.footer{
  flex-shrink: 0;
  text-align: center;
  color: white;
  margin-top: auto;
  position: relative;
  bottom: 0;
}
.a{
  color: blue;
}
.nav-container{
  justify-content: flex-end;
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
